import { useTranslation } from 'react-i18next'
import { ElevatedTitleCentered } from '../../../components/modules/ElevatedTitleCentered'

const anchorLinkSections = () => {
  const [t] = useTranslation()
  const data = [
    {
      title: t('CalculatorPageAuto.sidebarHeader', {
        defaultValue: 'Calculators',
      }),
      anchorLinks: [
        {
          to: '/calculators/auto',
          name: t('CalculatorPageAuto.sidebarLink1', {
            defaultValue: 'What will my car payments be?',
          }),
        },
        {
          to: '/calculators/auto/how-much-can-afford',
          name: t('CalculatorPageAuto.sidebarLink2', {
            defaultValue: 'What price car can I afford?',
          }),
        },
        {
          to: '/calculators/auto/lease-or-purchase',
          name: t('CalculatorPageAuto.sidebarLink3', {
            defaultValue: 'Should I buy or lease a car?',
          }),
        },
        {
          to: '/calculators/auto/refinance-auto',
          name: t('CalculatorPageAuto.sidebarLink4', {
            defaultValue: 'Should I refinance my car loan?',
          }),
        },
      ],
    },
  ]

  return data
}

export { anchorLinkSections }
export default anchorLinkSections

const CrossSellTout = () => {
  const [t] = useTranslation()

  const richHeadline = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            marks: [],
            value: t('CalculatorPageAuto.ctaHeadline', {
              defaultValue:
                "You've crunched the numbers. Now see how we can help you with your auto loan.",
            }),
            data: {},
          },
        ],
        data: {},
      },
    ],
  }

  return (
    <ElevatedTitleCentered
      richHeadline={richHeadline}
      buttonUrl="/loans/auto-loans"
      buttonCta={t('CalculatorPageAuto.ctaButton', {
        defaultValue: 'Learn more',
      })}
      buttonAlt={t('CalculatorPageAuto.ctaButtonAlt', {
        defaultValue: 'Learn more about auto loans',
      })}
    />
  )
}

export { CrossSellTout }
