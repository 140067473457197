import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { AutoMonthlyPaymentCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellTout } from './constants'
import { usePages } from '../constants'

const AutoPaymentsPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, autoPage } = usePages()

  const pageData = [
    {
      sectionTitle: t('AutoPaymentsPage.subTitle', {
        defaultValue: 'What will my car payments be?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('AutoPaymentsPage.textContent', {
                  defaultValue:
                    'Got your eye on a new car? A truck? Maybe a sensible sedan or a rocking off-roader? Complete the fields below with your best estimates to find out your monthly car payments and total interest.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: autoPage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('AutoPaymentsPage.pageTitle', {
              defaultValue: 'Auto Payment Calculator',
            })}
            pathname="/calculators/auto"
          />
          <CalculatorSectionModule
            pageTitle={t('AutoPaymentsPage.title', {
              defaultValue: 'Auto Calculators',
            })}
            sections={pageData}
            calculator={<AutoMonthlyPaymentCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellTout />
        </Layout>
      )}
    </StaticTemplate>
  )
}

AutoPaymentsPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { AutoPaymentsPage }
export default AutoPaymentsPage
